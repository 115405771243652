body {
  font-family: 'Roboto', sans-serif; /* Use a clean, modern font */
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003366; /* Dark blue background */
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.App-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  margin: 0;
}

h2 {
  color: #003366;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.TemperatureChecker {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
  gap: 20px;
  width: 100%; /* Ensures grid takes full width */
}

.state-card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  font-size: 1.2em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.state-card.ICE {
  border-left: 8px solid #e53935; /* Red border for ICE */
}

.state-card.NOICE {
  border-left: 8px solid #43a047; /* Green border for NO ICE */
}

.state-card button {
  background: none;
  border: none;
  font-size: 1.2em;
  text-align: left;
  width: 100%;
  padding: 0;
  color: #003366;
}

.state-card button:focus {
  outline: none;
}

.state-card .temperature-details {
  margin-top: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.state-card .temperature-details p {
  margin: 5px 0;
}
